import { useEffect, useState } from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import useHashChange from "hooks/useHashChange"
import usePath from "./usePath"

/**
 * Hook to handle the open state of an expandable component
 * @param id Id to check if the current hash is the same as the id
 * @param collapsed Default value for the open state
 * @param onChange Handler for the open state
 * @returns Handler for the open state and the open state
 */
export function useSemiControlledExpand(
    id: string | undefined,
    collapsed: boolean | undefined,
    onChange?: () => void
) {
    const isPrinting = useMediaQuery("print")
    const asPath = usePath(true)

    const [open, setOpen] = useState(
        Boolean(isPrinting || !collapsed || (id && asPath.includes(id)))
    )

    useHashChange(hash => {
        if (!id || !hash.startsWith(id)) return
        setOpen(true)
    })

    useEffect(() => {
        if (isPrinting) {
            setOpen(true)
        }
    }, [isPrinting])

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "f") {
                setOpen(true)
            }
        }

        window.addEventListener("keydown", handler)
        return () => {
            window.removeEventListener("keydown", handler)
        }
    }, [setOpen])

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const defaultOnChange = () => setOpen(open => !open)
    return { onChange: onChange ?? defaultOnChange, open }
}
