import { PortableTextTypeComponentProps } from "@portabletext/react";
import Accordion from "components/UI/Accordion";
import PortableText from "components/PortableText/PortableText";
import CollapsibleList from "components/PortableText/Types/Notes/CollapsibleList";
import { JSX, ReactNode, Ref } from "react";

// eslint-disable-next-line no-restricted-imports
import { Palette } from "@mui/material/styles/createPalette";
import { styled, useTheme } from "@mui/material/styles";
export interface NoteBaseProps {
  style: keyof Palette["notes"] | "collapsible" | "collapsible-list";
  title: string | JSX.Element;
  blockContent?: string;
  collapsed?: boolean;
  id?: string;
  children: ReactNode;
  buttonRef?: Ref<HTMLButtonElement>;
}
export default function Note({
  value
}: PortableTextTypeComponentProps<NoteBaseProps>) {
  const {
    style,
    id,
    collapsed,
    title,
    blockContent
  } = value;
  const theme = useTheme();
  const {
    summary,
    details
  } = theme.palette.notes[style as keyof Palette["notes"]] || {};
  const _title = typeof title === "string" ? <PortableText value={JSON.parse(title)} /> : title;
  const children = blockContent ? <PortableText value={JSON.parse(blockContent)} wrapImgWithGalleryLink /> : null;
  if (style === "collapsible-list") {
    return <CollapsibleList collapsed={collapsed} title={_title}>
                {children}
            </CollapsibleList>;
  }
  return <Accordion id={id} collapsed={collapsed} header={_title} level="h4" margin={theme.spacing(1, 2)} summaryBackground={summary.main} summaryHoverBackground={summary.hover} background={details} data-sentry-element="Accordion" data-sentry-component="Note" data-sentry-source-file="Note.tsx">
            <ContentWrapper data-sentry-element="ContentWrapper" data-sentry-source-file="Note.tsx">{children}</ContentWrapper>
        </Accordion>;
}
const ContentWrapper = styled("div")(({
  theme
}) => ({
  padding: theme.spacing(1, 2)
}));