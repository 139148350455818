import { useState } from "react"

export const useModal = () => {
    const [showModal, setShowModal] = useState(false)

    const showModalHandler = () => {
        setShowModal(true)
        document.body.style.overflow = "hidden"
    }

    const closeModalHandler = () => {
        setShowModal(false)
        document.body.style.overflow = "auto"
    }

    return { showModal, showModalHandler, closeModalHandler }
}
