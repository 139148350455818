import { PortableTextTypeComponentProps } from "@portabletext/react";
import { config } from "config";
import useUser from "hooks/useUser";
import { useSearchParams } from "next/navigation";
type PlaceholderType = "allowed-concurrent-login";
type PlaceholderProps = PortableTextTypeComponentProps<{
  name: PlaceholderType;
}>;
export default function Placeholder({
  value
}: PlaceholderProps) {
  const searchParams = useSearchParams();
  const allowed = searchParams?.get("allowed");
  const {
    user
  } = useUser();
  if (value.name === "allowed-concurrent-login") {
    const allowedConcurrency = allowed ?? config.auth.defaultMaxConcurrentLogins.toString();
    return <strong>{allowedConcurrency}</strong>;
  }
  if (value.name === "user-name" && user) {
    return <span>{user.name}</span>;
  }
  return null;
}