import { Icon } from "components/Icon";
import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { Variant } from "@mui/material/styles/createTypography";
import { useSemiControlledExpand } from "hooks/useSemiControlledExpand";
interface IProps {
  header?: string | React.ReactElement;
  children?: React.ReactNode;
  collapsed?: boolean | undefined;
  level?: Variant;
  id?: string;
  noPrint?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}
interface IStylingProps {
  summaryColor?: string;
  summaryBackground?: string;
  summaryHoverBackground?: string | undefined;
  background?: string;
  margin?: string;
  summaryMargin?: string;
  padding?: string;
  borderRadius?: string;
}
export default function Accordion(props: IProps & IStylingProps) {
  const theme = useTheme();
  const {
    id,
    header,
    children,
    collapsed,
    level,
    noPrint,
    summaryColor,
    summaryBackground,
    summaryHoverBackground,
    background,
    margin,
    summaryMargin,
    padding,
    borderRadius,
    ref
  } = props;
  const {
    open,
    onChange
  } = useSemiControlledExpand(id, collapsed ?? false);
  const isNoPrint = noPrint ? "no-print" : "";
  return <StyledAccordion id={id} background={background ?? theme.palette.grey.white} margin={margin ?? theme.spacing(0)} padding={padding ?? theme.spacing(0)} className={isNoPrint} ref={ref} data-sentry-element="StyledAccordion" data-sentry-component="Accordion" data-sentry-source-file="Accordion.tsx">
            <StyledHeading summaryHoverBackground={summaryHoverBackground} summaryBackground={summaryBackground ?? theme.palette.grey.white} summaryColor={summaryColor ?? theme.palette.text.default} summaryMargin={summaryMargin ?? theme.spacing(0.5, 0)} borderRadius={borderRadius ?? "0"} onClick={onChange} isOpen={open} aria-expanded={open} aria-controls="accordion-content" data-sentry-element="StyledHeading" data-sentry-source-file="Accordion.tsx">
                <Typography variant={level} data-sentry-element="Typography" data-sentry-source-file="Accordion.tsx">{header}</Typography>
                <Icon name={open ? "expandLess" : "expandMore"} className="no-print" data-sentry-element="Icon" data-sentry-source-file="Accordion.tsx" />
            </StyledHeading>
            <AccordionContent id="accordion-content" isOpen={open} data-sentry-element="AccordionContent" data-sentry-source-file="Accordion.tsx">
                <ContentWrapper data-sentry-element="ContentWrapper" data-sentry-source-file="Accordion.tsx">{children}</ContentWrapper>
            </AccordionContent>
        </StyledAccordion>;
}
interface IAccordionProps {
  background: string;
  margin: string;
  padding: string;
}
interface IHeadingProps {
  summaryBackground: string;
  summaryHoverBackground: string | undefined;
  summaryColor: string;
  summaryMargin: string;
  borderRadius: string;
  isOpen: boolean;
}
const StyledAccordion = styled("div")<IAccordionProps>(({
  theme,
  background,
  margin,
  padding
}) => ({
  background: background,
  scrollMarginTop: `${2 * theme.constants.height.header}px`,
  [theme.breakpoints.up("sm")]: {
    scrollMarginTop: `${theme.constants.height.breadcrumbs + theme.constants.height.header}px`
  },
  margin: margin,
  padding: padding,
  "@media print": {
    border: "1px solid black"
  }
}));
const StyledHeading = styled("button")<IHeadingProps>(({
  summaryBackground,
  summaryHoverBackground,
  summaryColor: color,
  summaryMargin: headerMargin,
  borderRadius,
  isOpen,
  theme
}) => ({
  background: summaryBackground,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: color,
  width: "100%",
  border: "none",
  borderRadius: isOpen ? `${borderRadius} ${borderRadius} 0 0` : borderRadius,
  padding: theme.spacing(0, 2),
  margin: headerMargin,
  maxHeight: 70,
  minHeight: 48,
  cursor: "pointer",
  textAlign: "left",
  "&:hover": {
    background: summaryHoverBackground
  },
  "& p": {
    margin: 0
  }
}));
const AccordionContent = styled("div")<{
  isOpen: boolean;
}>(({
  isOpen
}) => ({
  display: "grid",
  gridTemplateRows: isOpen ? "1fr" : "0fr",
  transition: "grid-template-rows 0.3s ease-in-out"
}));
const ContentWrapper = styled("div")(() => ({
  overflow: "hidden"
}));