import { PortableTextTypeComponentProps } from "@portabletext/react";
import Link from "components/Link";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { signIn } from "auth/signIn";
import { JSX } from "react";
interface ContentButtonBaseProps extends Pick<MuiButtonProps, "fullWidth" | "title"> {
  method: string;
  style: string;
  text: JSX.Element;
  url: string;
  id: string | null;
}
type ContentButtonProps = PortableTextTypeComponentProps<ContentButtonBaseProps>;
export default function Button({
  value
}: ContentButtonProps) {
  const {
    url: href,
    style,
    text: children,
    id: id,
    ...rest
  } = value;
  const variant = style === "primary" ? "filled" : "outlined";
  if (id?.endsWith("login-button")) {
    return <SignInButton id={id}>{children}</SignInButton>;
  }
  return <StyledLink button disableRipple variant={variant} size="large" href={href} id={id ?? ""} {...rest} data-sentry-element="StyledLink" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
            {children}
        </StyledLink>;
}
const StyledLink = styled(Link)(({
  theme
}) => ({
  margin: theme.spacing(0.5, 0),
  boxShadow: "none",
  "&:focus": {
    outline: "none",
    boxShadow: `0 0 0 3px ${theme.palette.cds[700]}`
  }
}));
interface ISignInButtonProps {
  id: string;
  children: JSX.Element;
}
function SignInButton({
  id,
  children
}: ISignInButtonProps) {
  const handleSignIn = () => {
    if (id.endsWith("force-login-button")) {
      signIn({
        authorization: {
          forceLogin: "true",
          prompt: "login"
        }
      });
    } else {
      signIn();
    }
  };
  return <StyledMuiButton onClick={handleSignIn} size="large" fullWidth variant="filled" id={id} data-sentry-element="StyledMuiButton" data-sentry-component="SignInButton" data-sentry-source-file="Button.tsx">
            {children}
        </StyledMuiButton>;
}
const StyledMuiButton = styled(MuiButton)(({
  theme
}) => ({
  margin: theme.spacing(0.5, 0)
}));