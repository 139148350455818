import { PortableTextMarkComponentProps } from "@portabletext/react";
import { Icon } from "components/Icon";
import EmiliaLink from "components/Link";
import { styled } from "@mui/material/styles";
export default function Link({
  children,
  value
}: PortableTextMarkComponentProps) {
  if (!value || !value.href) {
    return null;
  }
  const target = value.target ?? "_self";
  if (value.class?.includes("highlighted")) {
    return <StyledHighlightedLink href={value.href} target={target}>
                <Icon name="link" />
                {children}
            </StyledHighlightedLink>;
  }
  return <StyledLink href={value.href} target={target} data-sentry-element="StyledLink" data-sentry-component="Link" data-sentry-source-file="Link.tsx">
            {children}
        </StyledLink>;
}
const StyledLink = styled(EmiliaLink)(() => ({
  "& span": {
    whiteSpace: "normal",
    maxWidth: "none",
    display: "initial"
  }
}));
const StyledHighlightedLink = styled(EmiliaLink)(({
  theme
}) => ({
  textDecoration: "underline",
  border: `1px solid ${theme.palette.cds[700]}`,
  fontWeight: 700,
  display: "flex",
  width: "100%",
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  "&:hover": {
    textDecoration: "none"
  },
  "& span": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2)
  }
}));