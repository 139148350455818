import { PortableTextTypeComponentProps } from "@portabletext/react";
import { t } from "locales";
interface AudioEmbedProps {
  controls: boolean;
  title: string;
  children: {
    src: string;
    type: string;
  }[];
}
export default function Audio({
  value
}: PortableTextTypeComponentProps<AudioEmbedProps>) {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls data-sentry-component="Audio" data-sentry-source-file="Audio.tsx">
            {value.children.map((child, index) => <source key={index} src={child.src} type={child.type} />)}
            {/* Fallback for browsers that don't support <audio> */}
            <p>{t["audio-fallback"]}</p>
        </audio>
  );
}