import { PortableTextTypeComponentProps } from "@portabletext/react";
import PortableText from "components/PortableText/PortableText";
import { Modal } from "components/UI/Modal/Modal";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useModal } from "components/UI/Modal/useModal";
import { Icon } from "components/Icon";
import { t } from "locales";
interface TableProps {
  caption?: string;
  rows: Array<Array<{
    blockContent: string;
    colSpan?: number;
    rowSpan?: number;
    style?: string;
  }>>;
  style?: string;
}
export default function Table({
  value,
  isInline,
  index,
  renderNode
}: PortableTextTypeComponentProps<TableProps>) {
  const {
    showModal,
    showModalHandler,
    closeModalHandler
  } = useModal();
  return <>
            <TablePreviewWrapper data-sentry-element="TablePreviewWrapper" data-sentry-source-file="Table.tsx">
                <StyledButton variant="text" size="small" startIcon={<Icon name="expand-content" />} onClick={showModalHandler} data-sentry-element="StyledButton" data-sentry-source-file="Table.tsx">
                    {t.table["open-table"]}
                </StyledButton>
                <TableWrapper data-sentry-element="TableWrapper" data-sentry-source-file="Table.tsx">
                    <TablePreview data-sentry-element="TablePreview" data-sentry-source-file="Table.tsx">
                        <TableContent value={value} isInline={isInline} index={index} renderNode={renderNode} data-sentry-element="TableContent" data-sentry-source-file="Table.tsx" />
                        {value.caption && <PortableText value={JSON.parse(value.caption)} />}
                    </TablePreview>
                </TableWrapper>
            </TablePreviewWrapper>
            {showModal && <Modal title={t.table["table"]} isClosed={closeModalHandler}>
                    <TableWrapper>
                        <TableContent value={value} isInline={isInline} index={index} renderNode={renderNode} />
                    </TableWrapper>

                    {value.caption && <PortableText value={JSON.parse(value.caption)} />}
                </Modal>}
        </>;
}
const TablePreviewWrapper = styled("div")(({
  theme
}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginBottom: theme.spacing(2),
  "& table": {
    margin: 0
  }
}));
const TableWrapper = styled("div")(({
  theme
}) => ({
  backgroundColor: theme.palette.grey.white,
  "& table": {
    margin: 0
  },
  "& caption": {
    fontSize: "14px",
    padding: theme.spacing(0, 1)
  },
  [theme.breakpoints.up("sm")]: {
    border: `1px solid ${theme.palette.grey[500]}`
  }
}));
const StyledButton = styled(Button)(({
  theme
}) => ({
  margin: theme.spacing(1),
  alignSelf: "flex-end"
}));
const TablePreview = styled("div")(() => ({
  overflowX: "auto"
}));
function TableContent({
  value
}: PortableTextTypeComponentProps<TableProps>) {
  const classes = value.style?.split(",").join(" ") ?? "";
  const caption = value.caption;
  const rows = value.rows;
  return <MuiTable className={classes} data-sentry-element="MuiTable" data-sentry-component="TableContent" data-sentry-source-file="Table.tsx">
            {caption && <caption>
                    <PortableText value={JSON.parse(caption)} />
                </caption>}
            <TableBody data-sentry-element="TableBody" data-sentry-source-file="Table.tsx">
                {rows.map((row, rowIndex) =>
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={rowIndex}>
                        {row.map(({
          blockContent,
          style,
          ...props
        }, cellIndex) => <StyledTableCell emphasized={style === "em" ? "true" : "false"}
        // eslint-disable-next-line react/no-array-index-key
        key={cellIndex} {...props}>
                                    <PortableText value={JSON.parse(blockContent)} wrapImgWithGalleryLink />
                                </StyledTableCell>)}
                    </TableRow>)}
            </TableBody>
        </MuiTable>;
}
const StyledTableCell = styled(TableCell)<{
  emphasized: string;
}>(({
  emphasized,
  theme
}) => ({
  overflowWrap: "break-word",
  fontWeight: emphasized === "true" ? 700 : 400,
  border: `1px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(0, 1),
  fontSize: "14px",
  verticalAlign: "top",
  "& ul": {
    paddingLeft: theme.spacing(2)
  }
}));